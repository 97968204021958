export class BookProductDemo {

    contactNo: number;
    emailId: string;
    bookSlotDateTime: string;
    firstName: string;
    lastName: string;
    products: Array<string>;

    constructor() {
        this.contactNo = 0;
        this.emailId = '';
        this.bookSlotDateTime = '';
        this.firstName = '';
        this.lastName = '';
        this.products = [];

    }

}
