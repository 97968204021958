<app-header></app-header>
<!-- sub header progress bar -->
<div class="progressbar-header">

    <ul class="progressbar-menu">
        <li><a href="#one" translate>progressbar.progressbar1</a></li>
        <li><a href="#two" translate>progressbar.progressbar2</a></li>
        <li><a href="#three" translate>progressbar.progressbar3</a></li>
        <li><a href="#four" translate>progressbar.progressbar4</a></li>
    </ul>
</div>
<!--  slider -->
<div class="home-demo" dir="ltr">
    <div class="slider stick-dots">
        <div class="slide">
            <div class="slide__img">
                <img src="assets/images/home-banner1.jpeg" class="full-image animated"
                    data-animation-in="zoomInImage" />
            </div>
            <div class="slide__content">
                <div class="slide__content--headings">
                    <h2 class="animated" data-animation-in="fadeInUp" translate>home.banner1.title</h2>
                    <p class="animated" data-animation-in="fadeInUp" data-delay-in="0.3" translate>home.banner1.subtitle</p>
                </div>
            </div>
        </div>
        <div class="slide">
            <div class="slide__img">
                <img src="assets/images/banner.jpg" alt="" data-lazy="assets/images/banner.jpg"
                    class="full-image animated" data-animation-in="zoomInImage" />
            </div>
            <div class="slide__content">
                <div class="slide__content--headings">
                    <h2 class="animated" data-animation-in="fadeInUp" translate>home.banner2.title</h2>
                    <p class="animated" data-animation-in="fadeInUp" data-delay-in="0.3" translate>home.banner2.subtitle</p>
                </div>
            </div>
        </div>
        <div class="slide">
            <div class="slide__img">
                <img src="assets/images/banner1.jpg" alt="" data-lazy="assets/images/banner1.jpg"
                    class="full-image animated" data-animation-in="zoomInImage" />
            </div>
            <div class="slide__content">
                <div class="slide__content--headings">
                    <h2 class="animated" data-animation-in="fadeInUp" translate>home.banner3.title
                    </h2>

                </div>
            </div>
        </div>
        <div class="slide">
            <div class="slide__img">
                <img src="assets/images/banner2.jpg" alt="" data-lazy="assets/images/banner2.jpg"
                    class="full-image animated" data-animation-in="zoomInImage" />
            </div>
            <div class="slide__content">
                <div class="slide__content--headings">
                    <h2 class="animated" data-animation-in="fadeInUp" translate>home.banner4.title
                    </h2>
                    <p class="animated" data-animation-in="fadeInUp" data-delay-in="0.3" translate>home.banner4.subtitle
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- taking diversity new heights -->
<div class="diversity-section">
    <div class="diversity-wrap">
        <div>
            <span class="bell-icon">
                <i class="fa-solid fa-bell"></i>
            </span>
            <h2 data-aos="fade-down" translate>home.diversity-section.title</h2>
        </div>
        <p data-aos="fade-down" translate>home.diversity-section.subtitle</p>
        <a href="" class="common-btn" data-aos="fade-down">
            <i class="fa-solid fa-arrow-right-long"></i>
            <span translate>
                readmore
            </span>
        </a>

    </div>
</div>


<!-- about section  -->
<div class="about-section">
    <div class="container">
        <h2 data-aos="fade-up" translate>home.about-section.title</h2>

        <div class="about-top-image" data-aos="fade-up">
            <img src="assets/images/about-banner.jpg">
        </div>
        <div class="about-content" data-aos="fade-up">
            <p translate>home.about-section.about-content.p1</p>
            <p translate>home.about-section.about-content.p2</p>
            <a routerLink="/about" translate class="common-btn" translate>
                <i class="fa-solid fa-arrow-right-long"></i> readmore
            </a>
        </div>


    </div>
</div>

<!-- why choose us -->
<div class="about-section choose-advanced">
    <div class="container">
        <h2 data-aos="fade-up" translate>home.whyus-section.title
        </h2>
        <div class="why-choose-row">
            <div class="choose-col choose-col-img" data-aos="fade-up">
                <img src="assets/images/why-choose-banner.jpg">
            </div>
            <div class="about-content choose-col" data-aos="fade-up">
                <p translate>home.whyus-section.whyus-content.p1</p>
                <p translate>home.whyus-section.whyus-content.p2</p>
                <p translate>home.whyus-section.whyus-content.p3</p>

                <a href="" class="common-btn" translate>
                    <i class="fa-solid fa-arrow-right-long"></i> readmore
                </a>
            </div>
        </div>
    </div>
</div>



<section id="one">
    <!-- Voices of Change -->
    <div class="voice-change-wrap">
        <div class="container">
            <div class="voice-changer-inner">
                <h2 data-aos="fade-up" translate>
                    home.voice-change-section.title
                </h2>
                <p data-aos="fade-up" translate>home.voice-change-section.subtitle</p>
            </div>
            <div class="box-row">
                <div class="box-col" data-aos="fade-down" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <div class="box-img">
                        <img src="assets/images/Voice-of-Change_1.png">
                    </div>
                    <div class="box-content">
                        <h4 translate>home.voice-change-section.voice-change-content1.title</h4>
                        <h3 translate>home.voice-change-section.voice-change-content1.subtitle</h3>
                        <p translate>home.voice-change-section.voice-change-content1.p1</p>
                    </div>
                </div>
                <div class="box-col" data-aos="fade-down" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <div class="box-img">
                        <img src="assets/images/Voice-of-Change_2.png">
                    </div>
                    <div class="box-content">
                        <h4 translate>home.voice-change-section.voice-change-content2.title</h4>
                        <h3 translate>home.voice-change-section.voice-change-content2.subtitle</h3>
                        <p translate>home.voice-change-section.voice-change-content2.p1</p>
                    </div>
                </div>
                <div class="box-col" data-aos="fade-down" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <div class="box-img">
                        <img src="assets/images/Voice-of-Change_3.png">
                    </div>
                    <div class="box-content">
                        <h4 translate>home.voice-change-section.voice-change-content3.title</h4>
                        <h3 translate>home.voice-change-section.voice-change-content3.subtitle</h3>
                        <p translate>home.voice-change-section.voice-change-content3.p1</p>
                    </div>
                </div>
                <div class="box-col" data-aos="fade-down" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <div class="box-img">
                        <img src="assets/images/Voice-of-Change_4.png">
                    </div>
                    <div class="box-content">
                        <h4 translate>home.voice-change-section.voice-change-content4.title</h4>
                        <h3 translate>home.voice-change-section.voice-change-content4.subtitle</h3>
                        <p translate>home.voice-change-section.voice-change-content4.p1</p>
                    </div>
                </div>
            </div>
            <div class="btn-wrap">
                <a href="" class="common-btn" translate>
                    <i class="fa-solid fa-arrow-right-long"></i> home.voice-change-section.read-more
                </a>
            </div>
        </div>
    </div>
</section>

<!-- reimagine your career -->

<section id="two">
    <div class="join-us-wrap">
        <div class="container">
            <div class="mobile-icon">
                <img src="assets/images/logo-footer.png">
            </div>
            <div class="join-us-inner">
                <h2 translate>home.joinus-section.title
                </h2>
                <p translate>home.joinus-section.subtitle
                </p>
                <a href="" class="common-btn" translate>
                    <i class="fa-solid fa-arrow-right-long"></i>home.joinus-section.search-and-apply
                </a>
            </div>
        </div>
    </div>
    <div class="reimagine-career-wrapper">
        <div class="bg-video-wrap">
            <div class="container container2">
                <!-- SVG	 -->
                <div class="svg-container">
                </div>
                <div class="video-container">
                    <video src="assets/images/video2.mp4" loop muted autoplay>
                    </video>
                </div>

            </div>
            <div class="overlay">
            </div>
            <div class="reimagine-inner-wrap">
                <h3 data-aos="fade-up" translate>home.joinus-section.career.title
                </h3>
                <p data-aos="fade-up" translate>home.joinus-section.career.p1.1</p>
                <p data-aos="fade-up" translate>home.joinus-section.career.p1.2</p>
                <p data-aos="fade-up" translate>home.joinus-section.career.p2.1</p>
                <!-- <p data-aos="fade-up" translate>home.joinus-section.career.p2.2</p> -->
                <p data-aos="fade-up" translate>home.joinus-section.career.p3</p>
                <a href="" class="common-btn" data-aos="fade-up" translate> <i class="fa-solid fa-arrow-right-long"></i>home.joinus-section.search-and-apply</a>
            </div>
        </div>
    </div>
</section>

<!-- Case studies & stories -->
<section id="three">
    <div class="case-studies-stories">
        <div class="container">
            <div class="case-studies-inner">
                <h2 data-aos="fade-up" translate>home.case-study-section.title
                </h2>
                <p data-aos="fade-up" translate>home.case-study-section.subtitle
                </p>

            </div>
        </div>
    </div>
    <div class="slider-wrap">
        <div class="carousel">
            <div>
                <div class="card">
                    <div class="card-header">
                        <img src="assets/images/case-studie_1.png">
                    </div>
                    <div class="card-body">
                        <div class="card-content">
                            <h5>Data Analytics</h5>
                            <h4>Unlocking Insights</h4>
                            <p>Our cutting-edge solutions harness the power of data, unraveling patterns, and extracting actionable insights. From predictive modeling to real-time analytics, we empower businesses to make informed decisions, enhance operational efficiency, and stay ahead in today's data-driven landscape. With advanced algorithms and intuitive visualizations, our data analytics suite is the compass navigating enterprises towards success, ensuring they harness the full potential of their data for strategic advantage.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="card">
                    <div class="card-header">
                        <img src="assets/images/case-studie_2.png">
                    </div>
                    <div class="card-body">
                        <div class="card-content">
                            <h5>Accrediation</h5>
                            <h4>Education Quality Assurance ABET, NCAAA, NAAC</h4>
                            <p>Our consultation and support in aligning with NCAAA standards led to comprehensive academic improvements. The result? NCAAA accreditation, a testament to their commitment to academic excellence, institutional effectiveness, and continuous improvement.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="card">
                    <div class="card-header">
                        <img src="assets/images/case-studie_8.jpeg">
                    </div>
                    <div class="card-body">
                        <div class="card-content">
                            <h5>Energy</h5>
                            <h4>Solar Energy</h4>
                            <p> Our tailored solar solutions, coupled with smart grid technologies, enabled seamless integration into the existing infrastructure. The outcome? A 30% decrease in reliance on the conventional grid, translating into substantial cost savings and a significant reduction in carbon footprint.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="card">
                    <div class="card-header">
                        <img src="assets/images/case-studie_4.png">
                    </div>
                    <div class="card-body">
                        <div class="card-content">
                            <h5>ECommerce</h5>
                            <h4>Style meets Success in Fashion Retail</h4>
                            <p>Our ecommerce strategy seamlessly blended cutting-edge technology, user-centric design, and strategic marketing. The impact was profound – a remarkable 50% spike in conversion rates and a 30% increase in average order value.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="card">
                    <div class="card-header">
                        <img src="assets/images/case-studie_9.png">
                    </div>
                    <div class="card-body">
                        <div class="card-content">
                            <h5>Telecommunications</h5>
                            <h4>Revenue Assurance</h4>
                            <p>In the dynamic landscape of telecommunications, safeguarding revenue streams is paramount. Our Revenue Assurance solutions act as an impenetrable shield, ensuring that every transaction, billing cycle, and service offering is optimized for maximum profitability.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- across the globe -->

<section id="four">
    <div class="across-globe-wrap">
        <div class="container container3">
            <div class="svg-container-op">
            </div>

            <div class="across-globe-inner video-container-op">
                <p translate>home.across-globe-section.p1</p>
                <h5>Shabbir Hassan
                </h5>
                <h5>Chair & CEO
                </h5>
                <ul class="social-media-wrap">
                    <li><a href="">Twitter</a></li>
                    <li><a href="">LinkedIn</a></li>
                </ul>
                <a href="" class="common-btn" translate> <i class="fa-solid fa-arrow-right-long"></i>home.across-globe-section.see-how-we-work-with-clients
                </a>
            </div>
        </div>

    </div>
    <div class="mobile-icon">
        <img src="assets/images/logo-footer.png">
    </div>
    <h3 class="salogan-text" translate>home.across-globe-section.let_there_be_change</h3>
</section>
<app-footer></app-footer>