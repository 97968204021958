import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BookProductDemo } from '../model/book-product-demo';
import { ContactDetails } from '../model/contact-details';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
    }), responseType: 'text' as 'json'
  };

  constructor(private httpClient: HttpClient) {

  }

  saveContactUs(contactDetails: ContactDetails): Observable<ContactDetails> {
    return this.httpClient.post<ContactDetails>
      ('http://localhost:8092/data-dimensions/customer-service/v1/api/customer/contact-us',
      contactDetails, this.httpOptions);
  }
}
