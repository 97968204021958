import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BookProductDemo } from '../model/book-product-demo';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
    }), responseType: 'text' as 'json'
  };

  constructor(private httpClient: HttpClient) {

  }

  getProducts(): Observable<Array<string>> {
    return this.httpClient.get<Array<string>>('http://localhost:8092/data-dimensions/customer-service/v1/api/customer/product');
  }

  getServices(): Observable<Array<string>> {
    return this.httpClient.get<Array<string>>('http://localhost:8092/data-dimensions/customer-service/v1/api/customer/service');
  }

  scheduleProductDemo(bookProductDemo: BookProductDemo): Observable<BookProductDemo> {
    return this.httpClient.post<BookProductDemo>
      ('http://localhost:8092/data-dimensions/customer-service/v1/api/customer/product/demo/booking',
        bookProductDemo, this.httpOptions);
  }
}
