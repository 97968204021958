import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomValidationService {

  constructor() { }

  public validateCharacterForNumeric(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  public validateCharacterForAlphabets(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Albhabets A-Z or a-z
    if (!((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122))) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  public validateCharacterPressedForEmail(event: any) {
    // Only Albhanumeric and special characters . @
    var regex = new RegExp("^[a-zA-Z0-9 @.]+$");
    var str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (regex.test(str)) {
      return true;
    }
    event.preventDefault();
    return false;
  }

  public preventPaste(event: any) {
    event.preventDefault();
    return false;
  }
}
