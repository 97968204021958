<!-- header -->
<section id="main-header">
    <header>
        <nav class="main-header">
            <div class="left-header">
                <a href="/" class="logo-wrap">
                    <img src="assets/images/new-logo.png" alt="">
                </a>
                <a id="menu-icon" (click)="toggleMenu()">&#9776;</a>

                <ul class="main-menu-wrap">
                    <li><a routerLink="/about" translate>header.about</a></li>
                    <li><a routerLink="/service" translate>header.services</a></li>
                    <li><a routerLink="/product" translate>header.product</a></li>
                    <li><a routerLink="/book-demo" translate>header.book-demo</a></li>
                    <li><a routerLink="/contact" translate>header.contact</a></li>
                </ul>
            </div>
            <ul class="right-header">
                <li class="search-wrap">
                    <a href="search.html">
                        <img src="./assets/images/icons8-search.svg" class="search-img">
                    </a>
                </li>
                <li>
                    <a href="">Sign In</a>
                </li>
                <li class="dropdown">
                    <button onclick="myFunction()" class="dropbtn">
                        <img src="assets/images/usa.jpeg"></button>
                    <div id="myDropdown" class="dropdown-content">
                        <div class="language-listing">
                            <ul>
                                <li>
                                    <!-- United Kingdom (English) -->
                                <li class="default" style="font-weight:700;" (click)="toggleLanguage('en')">Default (USA) </li>
                                <li class="dropdown-header ucase">All COUNTRIES &amp; LANGUAGES</li>
                                <li role="none">
                                    <a data-linktype="language"
                                        data-analytics-content-type="language" data-linkcomponentname="top nav"
                                        data-analytics-link-name=" United Kingdom" role="menuitem" (click)="toggleLanguage('ar')">
                                        <span>
                                            <img src="./assets/images/soudi-arab.jpeg">
                                        </span>Arabic</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
            </ul>
        </nav>
    </header>
</section>