import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../services/customer-service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

  products: Array<string> = [];
  errMsg: string = '';
  constructor(public customerService: CustomerService
    ) { }
    ngOnInit(): void {
      this.customerService.getProducts().subscribe(response => {
        this.products = response;
      }, error => {
        console.log('Error:' + error.message);
        if (error.status === 404) {
          this.errMsg = 'Product Not Found';
        } else {
          this.errMsg = 'Something went, please try again';
        }
      });
    }
}
