<app-header></app-header>
<!-- services  -->
<div class="service-banner">
    <div class="container">
        <div class="service-wrap">
            <h2>Products</h2>
        </div>
    </div>
</div>

<!-- product page -->
<div class="product-wrap">
    <div class="container">

        <!-- <div class="product-inner-wrap" data-aos="fade-right">
            <div class="product-content">
                <h3>DART
                </h3>
                <p>DART : Revenue Assurance & Monitoring System (Data Analysis & Reporting Tool) It is capable enough to provide the solution for the following main causes of revenue leakages:</p>
                <ul>
                    <li>Poor System IntegrationHigh level of manual process</li>
                    <li>Uncontrolled changes in management</li>
                    <li>Ineffective/Incomplete testing strategy</li>
                    <li>OSS/BSS system not fit for business</li>
                    <li>Poor configuration management</li>
                    <li>Poor reference data management</li>
                    <li>Outdated/misleading business rules</li>
                </ul>
            </div>
            <div class="product-img">
                <img src="assets/images/dart-img.png">
            </div>
        </div>
        <div class="product-inner-wrap" data-aos="fade-left">
            <div class="product-content">
                <h3>DART
                </h3>
                <p>DART : Revenue Assurance & Monitoring System (Data Analysis & Reporting Tool) It is capable enough to provide the solution for the following main causes of revenue leakages:</p>
                <ul>
                    <li>Poor System IntegrationHigh level of manual process</li>
                    <li>Uncontrolled changes in management</li>
                    <li>Ineffective/Incomplete testing strategy</li>
                    <li>OSS/BSS system not fit for business</li>
                    <li>Poor configuration management</li>
                    <li>Poor reference data management</li>
                    <li>Outdated/misleading business rules</li>
                </ul>
            </div>
            <div class="product-img">
                <img src="assets/images/mediation-img.png">
            </div>
        </div> -->
        <div class="product-inner-wrap" data-aos="fade-right">
            <div class="product-content">
                <h3 translate>product.title
                </h3>
                <p class="text-justify" translate>product.p1</p>
                <ul>
                    <li *ngFor="let product of products">
                        {{product}}
                    </li>
                </ul>
            </div>
            <div class="product-img">
                <img src="assets/images/reart-img.png">
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>