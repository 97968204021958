<app-header></app-header>

<!-- contact  -->
<div class="service-banner">
    <div class="container">
        <div class="service-wrap">
            <h2>Contact Us</h2>
        </div>
    </div>
</div>

<!-- contact form -->
<div class="contact-form-wrap">
    <div class="container">
        <div class="form-wrapper">
            <div class="form-left-wrap">
                <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                    <div class="form-group-wrap">
                        <div class="form-group">
                            <label for="firstName" translate>contact-us.firstname</label>
                            <input type="text" formControlName="firstName"
                                (keypress)="validateCharacterForAlphabets($event)" (paste)="preventPaste($event)"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                            <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback pb-3">
                                <div *ngIf="f.firstName.errors.required" translate>contact-us.firstname-required-error</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="lastName" translate>contact-us.lastname</label>
                            <input type="text" formControlName="lastName"
                                (keypress)="validateCharacterForAlphabets($event)" (paste)="preventPaste($event)"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                            <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback pb-3">
                                <div *ngIf="f.lastName.errors.required" translate>contact-us.lastname-required-error</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="email" translate>contact-us.emailid</label>
                        <input type="text" formControlName="email" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" (paste)="preventPaste($event)"
                            (keypress)="validateCharacterPressedForEmail($event)" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback pb-3">
                            <div *ngIf="f.email.errors.required" translate>contact-us.email-required-error</div>
                            <div *ngIf="f.email.errors.email" translate>contact-us.email-validation-error</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="phone" translate>contact-us.phone</label>

                        <input type="text" maxlength="15" formControlName="contact" class="form-control"
                            (paste)="preventPaste($event)" [ngClass]="{ 'is-invalid': submitted && f.contact.errors }"
                            (keypress)="validateCharacterForNumeric($event)" />
                        <div *ngIf="submitted && f.contact.errors" class="invalid-feedback pb-3">
                            <div *ngIf="f.contact.errors.required" translate>contact-us.phone-required-error</div>
                            <div *ngIf="f.contact.errors.minlength" translate>contact-us.phone-validation-error</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="subject" translate>contact-us.subject</label>
                        <input type="text" formControlName="subject" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.subject.errors }" />
                        <div *ngIf="submitted && f.subject.errors" class="invalid-feedback pb-3">
                            <div *ngIf="f.subject.errors.required" translate>contact-us.subject-required-error</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="message" translate>contact-us.your-message</label>
                        <textarea placeholder="Your message*" style="height:100px" formControlName="message"
                            [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>

                        <div *ngIf="submitted && f.message.errors" class="invalid-feedback pb-3">
                            <div *ngIf="f.message.errors.required" translate>contact-us.your-message-required-error</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="g-recaptcha">
                            <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha"
                                (success)="handleSuccess($event)">
                            </ngx-recaptcha2>
                            <div class="invalid-feedback d-block pb-3">
                                <div *ngIf="isInvalidCaptcha">Captch is required</div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="successMsg" class="alert alert-success" role="alert">
                        {{successMsg}}
                    </div>
                    <div *ngIf="errMsg" class="alert alert-danger" role="alert">
                        {{errMsg}}
                    </div>
                    <input type="submit" value="Submit">
                </form>
            </div>
            <div class="contact-right-wrap">


                <!-- Accordion -->
                <div class="accordion-container" id="accordion">
                    <div class="acc">
                        <div class="acc-head" id="headingOne">
                            <p class="flag-text-wrap collapsed" data-toggle="collapse" data-target="#collapseOne"
                                aria-expanded="true" aria-controls="collapseOne"> <span class="flag-icon">
                                    <img src="assets/images/soudi-arab.jpeg">
                                </span> Saudi Arabia</p>
                        </div>
                        <div id="collapseOne" class="card-body collapse show" aria-labelledby="headingOne"
                            data-parent="#accordion">
                            <div class="content" style="display: block;">
                                <div class="address-row">
                                    <div class="address-col">
                                        <h4 translate>contact-us.want-to-talk-to-us</h4>
                                        <p>+966535252776</p>
                                    </div>
                                    <div class="address-col">
                                        <h4 translate>contact-us.want-to-talk-to-support</h4>
                                        <p><a href="">enquiry@datadimens.com</a></p>
                                    </div>
                                    <div class="address-col">
                                        <h4 translate>contact-us.pay-us-visit</h4>
                                        <p>
                                            Street 60, Al-Hawiya<br>
                                            P.O. Box 11099<br>
                                            Taif 21944

                                        </p>
                                    </div>
                                </div>
                                <div class="map-wrapper">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3713.3895126331154!2d40.48384807526838!3d21.45322798030447!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDI3JzExLjYiTiA0MMKwMjknMTEuMSJF!5e0!3m2!1sen!2sin!4v1700475453580!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    <!-- <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d55840.297594724325!2d77.75583645!3d28.986819850000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1654197418213!5m2!1sen!2sin"
                                        width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                                        referrerpolicy="no-referrer-when-downgrade"></iframe> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="acc">
                        <div class="acc-head" id="headingTwo">
                            <p class="flag-text-wrap collapsed" data-toggle="collapse" data-target="#collapseTwo"
                                aria-expanded="false" aria-controls="collapseTwo"><span class="flag-icon">
                                    <img src="assets/images/India.jpg">
                                </span> India</p>
                        </div>
                        <div id="collapseTwo" class="card-body collapse" aria-labelledby="headingTwo"
                            data-parent="#accordion">
                            <div class="content" style="display: block;">
                                <div class="address-row">
                                    <div class="address-col">
                                        <h4 translate>contact-us.want-to-talk-to-us</h4>
                                        <p>+917784848810</p>
                                    </div>
                                    <div class="address-col">
                                        <h4 translate>contact-us.want-to-talk-to-support</h4>
                                        <p><a href="">shabbir@codecompass.in</a></p>
                                    </div>
                                    <div class="address-col">
                                        <h4 translate>contact-us.pay-us-visit</h4>
                                        <p>
                                            Sector 11, Laxmi Garden<br>
                                            Khandsa Road<br>
                                            Gurugram, Haryana - 122001

                                        </p>
                                    </div>
                                </div>
                                <div class="map-wrapper">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3507.9012186147884!2d77.014738!3d28.452394!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18266714ad7d%3A0x2e736082c869538d!2s466%2F23%2C%20Laxmi%20Garden%2C%20Sector%2011%2C%20Gurugram%2C%20Haryana%20122001%2C%20India!5e0!3m2!1sen!2sus!4v1700475759325!5m2!1sen!2sus" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="acc">
                        <div class="acc-head" id="headingThree">
                            <p class="flag-text-wrap collapsed" data-toggle="collapse" data-target="#collapseThree"
                                aria-expanded="false" aria-controls="collapseThree"><span class="flag-icon">
                                    <img src="assets/images/ghana-flag.png">
                                </span> Guinea</p>
                        </div>
                        <div id="collapseThree" class="card-body collapse" aria-labelledby="headingThree"
                            data-parent="#accordion">
                            <div class="content" style="display: block;">
                                <div class="address-row">
                                    <div class="address-col">
                                        <h4 translate>contact-us.want-to-talk-to-us</h4>
                                        <p>+60193397590</p>
                                    </div>
                                    <div class="address-col">
                                        <h4 translate>contact-us.want-to-talk-to-support</h4>
                                        <p><a href="">enquiry@analytiray.com</a></p>
                                    </div>
                                    <div class="address-col">
                                        <h4 translate>contact-us.pay-us-visit</h4>
                                        <p>
                                            Street 60, Al-Hawiya<br>
                                            P.O. Box 11099<br>
                                            Taif 21944

                                        </p>
                                    </div>
                                </div>
                                <div class="map-wrapper">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d55840.297594724325!2d77.75583645!3d28.986819850000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1654197418213!5m2!1sen!2sin"
                                        width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>