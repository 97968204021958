import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BookProductDemo } from '../model/book-product-demo';
import { CustomValidationService } from '../services/custom-validation.service';
import { CustomerService } from '../services/customer-service';
import { validateProductCheckboxes } from './validate-products-checkbox';

@Component({
  selector: 'app-book-demo',
  templateUrl: './book-demo.component.html',
  styleUrls: ['./book-demo.component.css']
})

export class BookDemoComponent implements OnInit {

  bookDemoForm: FormGroup = new FormGroup({});

  successMsg: string = '';
  errMsg: string = '';
  bookSlotErrMsg: string = '';
  siteKey: string = "6Lf6kqYgAAAAABJHHJ_YFM3XbESt2HNKzd6lW11u";

  submitted: boolean = false;
  isInvalidBookSlot: boolean = false;
  isInvalidCaptcha: boolean = false;
  products: Array<string> = [];


  constructor(
    public formBuilder: FormBuilder,
    public customValidationService: CustomValidationService,
    public customerService: CustomerService
  ) { }

  get f() { return this.bookDemoForm.controls; }

  get productsFormArray() {
    return this.f.productCheckBoxGrp as FormArray;
  }

  ngOnInit() {
    const dateTime = new Date();
    this.bookDemoForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      contact: ['', [Validators.required, Validators.maxLength(15)]],
      productCheckBoxGrp: new FormArray([], validateProductCheckboxes()),
      bookingSlotDatetime: ['', Validators.required],
      recaptcha: ['', Validators.required]
    });
    this.addProductCheckboxesToForm();
  }

  private addProductCheckboxesToForm() {
    this.customerService.getProducts().subscribe(response => {
      this.products = response;
      this.products.forEach(() => this.productsFormArray.push(new FormControl(false)));
    }, error => {
      console.log('Error:' + error.message);
      if (error.status === 404) {
        this.errMsg = 'Product Not Found';
      } else {
        this.errMsg = 'Something went, please try again';
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    this.successMsg = '';
    this.errMsg = '';

    const selectedProducts = this.bookDemoForm.value.productCheckBoxGrp.map(
      (checked: any, i: number) => checked ? this.products[i] : null).filter((v: null) => v !== null);

    if (this.f.recaptcha.value === '') {
      this.isInvalidCaptcha = true;
      return;
    } 

    if (this.bookDemoForm.invalid) {
      return;
    }

    if(this.validateBookSlotDateTime(this.f.bookingSlotDatetime.value)){
      return;
    }

    let bookProductDemo = new BookProductDemo();
    bookProductDemo.emailId = this.f.email.value;
    bookProductDemo.firstName = this.f.firstName.value;
    bookProductDemo.lastName = this.f.lastName.value;
    bookProductDemo.bookSlotDateTime = this.f.bookingSlotDatetime.value;
    bookProductDemo.contactNo = this.f.contact.value;
    bookProductDemo.products = selectedProducts;

    this.customerService.scheduleProductDemo(bookProductDemo).subscribe(response => {
      this.bookDemoForm.reset();
      this.submitted = false;
      this.successMsg = 'Demo slot is scheduled successfully'
    }, error => {
      console.log('Error:' + error.message);
      if (error.status === 409) {
        this.errMsg = 'Booking slot is already exists!';
      } else {
        this.errMsg = 'Something went, please try again';
      }
    });
  }

  validateBookSlotDateTime(date: any):boolean {
    this.isInvalidBookSlot = false;
    this.bookSlotErrMsg = '';
    console.log('date-'+date);

    if (date) {
      const currDate = new Date();
      const today = new Date(new Date().setHours(currDate.getHours() + 2));
      const dateToCheck = new Date(date);
      if (dateToCheck < today) {
        this.isInvalidBookSlot = true;
        this.bookSlotErrMsg = 'Invalid book slot datetime, please select booking slot atleast after 2 hours from current datetime.';
      } else {
        this.isInvalidBookSlot = false;
      }
    } else {
      this.bookSlotErrMsg = 'Book Slot Date is required';
    }
    return this.isInvalidBookSlot;
  }

  validateCharacterForNumeric(event: any) {
    return this.customValidationService.validateCharacterForNumeric(event);
  }

  validateCharacterForAlphabets(event: any) {
    return this.customValidationService.validateCharacterForAlphabets(event);
  }

  validateCharacterPressedForEmail(event: any) {
    return this.customValidationService.validateCharacterPressedForEmail(event);
  }

  preventPaste(event: any) {
    return this.customValidationService.preventPaste(event);
  }

  handleSuccess(event: any) {
    this.isInvalidCaptcha = false;
  }
}
