<!-- about section  -->
<app-header></app-header>
<!-- about  -->
<div class="service-banner">
    <div class="container">
        <div class="service-wrap">
            <h2 translate>about.heading</h2>
        </div>
    </div>
</div>
<div class="about-page-section">
    <div class="container">
        <div class="about-inner-wrap" data-aos="fade-up">

            <h3 translate>about.heading</h3>

            <p class="text-justify" translate>about.p1</p>
            <p class="text-justify" translate>about.p2</p>
            <p class="text-justify" translate>about.p3</p>
            <p class="text-justify" translate>about.p4</p>
            <ul>
                <li translate>about.li-1</li>
                <li translate>about.li-2</li>
                <li translate>about.li-3</li>
                <li translate>about.li-4</li>
            </ul>
        </div>
    </div>
</div>
<app-footer></app-footer>