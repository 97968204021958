import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../services/customer-service';

@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.css']
})
export class ServiceDetailsComponent implements OnInit {

  services: Array<string> = [];
  errMsg: string = '';

  constructor(public customerService: CustomerService
  ) { }
  ngOnInit(): void {
    this.customerService.getServices().subscribe(response => {
      this.services = response;
    }, error => {
      console.log('Error:' + error.message);
      if (error.status === 404) {
        this.errMsg = 'Service Not Found';
      } else {
        this.errMsg = 'Something went, please try again';
      }
    });
  }

}
