import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
//declare let AOS: any;

// import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
declare var $: any;

@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
  //,providers: [NgbCarouselConfig]  // add NgbCarouselConfig to the component providers

})
export class AppComponent implements OnInit {

  dirText: string = 'rtl';
  isJqueryWorking: string;

  // receiveMessage($event: string) {
  //   console.log('receiveMessage'+$event);
  //   this.dirText = $event as unknown as Direction;
  //   //const htmlTag = this.document.getElementsByTagName("body")[0] as HTMLBodyElement;
  //   //htmlTag.dir=this.dirText;
  // }

  ngOnInit() {
    console.log('no');
    //AOS.init();

    $(document).ready(() => {
      this.isJqueryWorking = 'Jquery is working !!!';
      console.log('yes');
    });
  }


  constructor(private translateService: TranslateService, @Inject(DOCUMENT) private document: Document) {
    translateService.addLangs(['ar', 'en']);
    this.translateService.use('en');
    //const htmlTag = this.document.getElementsByTagName("body")[0] as HTMLBodyElement;
    //htmlTag.dir=this.dirText;
    //this.changeCssFile('ar');


    this.isJqueryWorking = 'Jquery is working !!!';
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang == 'ar') {
        this.dirText = 'rtl';
      }
      else {
        this.dirText = 'ltr';
      }


      // let htmlTag = this.document.getElementsByTagName('html')[0] as HTMLHtmlElement;
      // htmlTag.dir = this.dirText;
      const htmlTag = this.document.getElementsByTagName("header")[0] as HTMLHtmlElement;
      htmlTag.dir = this.dirText;
      const htmlTagFooter = this.document.getElementsByTagName("footer")[0] as HTMLHtmlElement;
      htmlTagFooter.dir = this.dirText;

      const classesToSetDir = ["progressbar-menu", "about-inner-wrap", "product-inner-wrap", "product-inner-wrap", "contact-form-wrap", "contact-form-wrap" /* add other class names here */];

      for (const className of classesToSetDir) {
        const elements = this.document.getElementsByClassName(className);
        for (let i = 0; i < elements.length; i++) {
          const element = elements[i] as HTMLHtmlElement;
          element.dir = this.dirText;
        }
      }



      this.translateService.setDefaultLang(event.lang);
      this.translateService.use(event.lang);
      this.changeCssFile(event.lang);
    });
  }

  changeCssFile(lang: string) {
    // console.log('changeCssFile='+lang);
    let headTag = this.document.getElementsByTagName('head')[0] as HTMLHeadElement;
    let existingLink = this.document.getElementById('langCss') as HTMLLinkElement;
    let bundleName = lang === 'ar' ? 'arabicStyle.css' : 'englishStyle.css';
    if (existingLink) {
      existingLink.href = bundleName;
    } else {
      let newLink = this.document.createElement('link');
      newLink.rel = 'stylesheet';
      newLink.type = 'text/css';
      newLink.id = 'langCss';
      newLink.href = bundleName;
      headTag.appendChild(newLink);
    }
  }

}
