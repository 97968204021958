export class ContactDetails {
    contactNo: number;
    emailId: string;
    subject: string;
    firstName: string;
    lastName: string;
    message: string;

    constructor() {
        this.contactNo = 0;
        this.emailId = '';
        this.subject = '';
        this.firstName = '';
        this.lastName = '';
        this.message = ''

    }
}
