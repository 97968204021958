import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { BookDemoComponent } from './book-demo/book-demo.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { ProductComponent } from './product/product.component';
import { ServiceDetailsComponent } from './service-details/service-details.component';
import { BigDataProcessingComponent } from './big-data-processing/big-data-processing.component';
import { DataScienceComponent } from './data-science/data-science.component';
import { WebAndMobileComponent } from './web-and-mobile/web-and-mobile.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'service', component: ServiceDetailsComponent },
  { path: 'product', component: ProductComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'book-demo', component: BookDemoComponent },
  { path: 'big-data', component: BigDataProcessingComponent},
  { path: 'data-science', component: DataScienceComponent},
  { path: 'web-and-mobile', component: WebAndMobileComponent},

  { path: '', component: HomeComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
