<app-header></app-header>
<!-- services  -->
<div class="service-banner">
    <div class="container">
        <div class="service-wrap">
            <h2 translate>service.title</h2>
        </div>
    </div>
</div>

<!-- services block wrap -->
<div class="service-block-wrap">
    <div class="container_service">
        <div class="services_main">
            <div class="service_left">
                <ul>
                    <li  routerLinkActive="active-link"><a routerLink="/big-data">Big Data Processing</a></li>
                    <li  routerLinkActive="active-link"><a routerLink="/data-science" >Data Science</a></li>
                    <li  routerLinkActive="active-link"><a routerLink="/web-and-mobile" >Web And Mobile Application</a></li>
                </ul>
            </div>
            <div class="service_right">
                <div class="product-inner-wrap" data-aos="fade-right">
                    <div class="service-content">
                        <h3 translate>DATA SCIENCE</h3>
                        <h5>Advanced Analytics:</h5>
                        <p>Navigate the complex landscape of data with confidence. Our team of seasoned data scientists utilizes advanced analytics techniques to extract meaningful patterns and trends, providing you with the intelligence needed to make informed decisions.</p>
            
                        <h5>Machine Learning:</h5>
                        <p>From predictive modeling to machine learning algorithms, we leverage cutting-edge techniques to build intelligent systems that learn and adapt. Enhance efficiency, accuracy, and performance across your business operations.</p>
            
                        <h5>Big Data Solutions:</h5>
                        <p>In the era of big data, we specialize in handling vast amounts of information. Our scalable solutions ensure that your organization can seamlessly process, analyze, and derive actionable insights from even the most massive datasets.</p>
            
                        <h5>Predictive Analytics:</h5>
                        <p>Anticipate future trends and make proactive decisions with our predictive analytics solutions. We turn historical data into a strategic asset, helping you stay ahead of the competition and respond to market changes with confidence.</p>
            
                        <h5>Data Visualization:</h5>
                        <p>Transform complex data into visually compelling stories. Our data visualization experts craft intuitive dashboards and reports that make it easy for stakeholders to grasp insights quickly and make data-driven decisions.
                        </p>
            
                        <h5>Customized Solutions:</h5>
                        <p>Recognizing that every business is unique, we tailor our data science solutions to address your specific challenges and objectives. Whether you need anomaly detection, recommendation systems, or custom algorithms, we've got you covered.</p>
            
                    </div>
                </div>
            </div>
        </div>
        

    </div>
</div>
<app-footer></app-footer>