import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private translateService: TranslateService) {
    this.translateService.addLangs(['en', 'ar']);
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
  }
 
  ngOnInit(): void {
  }

  toggleLanguage(lang:string){
    this.translateService.use(lang); 
  }

  toggleMenu() {
    const menuWrap = document.querySelector('.main-menu-wrap');
    if (menuWrap) {
      menuWrap.classList.toggle('expand');
    }
  }
}
