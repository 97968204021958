<app-header></app-header>
<!-- services  -->
<div class="service-banner">
    <div class="container">
        <div class="service-wrap">
            <h2 translate>service.title</h2>
        </div>
    </div>
</div>

<!-- services block wrap -->
<div class="service-block-wrap">
    <div class="container_service">
        <div class="services_main">
            <div class="service_left">
                <ul>
                    
                    <li  routerLinkActive="active-link"><a routerLink="/big-data">Big Data Processing</a></li>
                    <li  routerLinkActive="active-link"><a routerLink="/data-science" >Data Science</a></li>
                    <li  routerLinkActive="active-link"><a routerLink="/web-and-mobile" >Web And Mobile Application</a></li>
                </ul>
            </div>
            <div class="service_right">
                <div class="product-inner-wrap" data-aos="fade-right">
                    <div class="product-content">
                        <h3 translate>service.title
                        </h3>
                        <p translate>service.p1
                        </p>
                        <p translate>service.p2
                        </p>
                        <ul>
                            <li *ngFor="let service of services">
                                {{service}}
                            </li>
                        </ul>
                    </div>
                    <div class="product-img">
                        <div class="service-slider">
                            <div>
                                <div class="service-slide">
                                    <img src="assets/images/service.png">
                                </div>
                            </div>
                            <div>
                                <div class="service-slide">
                                    <img src="assets/images/service2.png">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="service-content">
                        <h3 translate>BIG DATA PROCESSING AND DATA ANALYTICS</h3>
                        <p>Welcome to the forefront of data evolution at Data Dimension, where we redefine possibilities through cutting-edge Big Data Processing and Data Analytics solutions.</p>
                        <a routerLink="/big-data">Learn More</a>
                    </div>
                    <div class="service-content">
                        <h3 translate>DATA SCIENCE</h3>
                        <h5>Advanced Analytics:</h5>
                        <p>Navigate the complex landscape of data with confidence. Our team of seasoned data scientists utilizes advanced analytics techniques to extract meaningful patterns and trends, providing you with the intelligence needed to make informed decisions.</p>
                        <a routerLink="/data-science">Learn More</a>
                    </div>
            
                    <div class="service-content">
                        <h3 translate>Web And Mobile Application Developement</h3>
                        <p>At Data Dimension, we are passionate about transforming ideas into innovative and user-centric mobile and web applications. As a leading force in the digital realm, we bring creativity, expertise, and cutting-edge technology together to build solutions that exceed expectations.</p>
                        <a routerLink="/web-and-mobile">Learn More</a>
                    </div>

                </div>
            </div>
        </div>
        

        
        

        <div class="service-content">
            <h3 translate>service.why.title
            </h3>
            <p translate>service.why.p1
            </p>
            <p translate>service.why.p2</p>
            <p translate>service.why.p3</p>
        </div>

    </div>
</div>
<app-footer></app-footer>