import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ContactDetails } from '../model/contact-details';
import { ContactService } from '../services/contact.service';
import { CustomValidationService } from '../services/custom-validation.service';
import { NgbAccordionConfig, NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup = new FormGroup({});

  successMsg: string = '';
  errMsg: string = '';
  bookSlotErrMsg: string = '';
  siteKey: string = "6Lf6kqYgAAAAABJHHJ_YFM3XbESt2HNKzd6lW11u";

  submitted: boolean = false;
  isInvalidCaptcha: boolean = false;


  constructor(
    public formBuilder: FormBuilder,
    public customValidationService: CustomValidationService,
    public contactService: ContactService
  ) { }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      contact: ['', [Validators.required, Validators.maxLength(15)]],
      subject: ['', Validators.required],
      message: ['', Validators.required],
      recaptcha: ['', Validators.required]
    });
  }

  get f() { return this.contactForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.successMsg = '';
    this.errMsg = '';

    if (this.f.recaptcha.value === '') {
      this.isInvalidCaptcha = true;
      return;
    }

    if (this.contactForm.invalid) {
      return;
    }



    let contactDetails = new ContactDetails();
    contactDetails.emailId = this.f.email.value;
    contactDetails.firstName = this.f.firstName.value;
    contactDetails.lastName = this.f.lastName.value;
    contactDetails.subject = this.f.subject.value;
    contactDetails.contactNo = this.f.contact.value;
    contactDetails.message = this.f.message.value;;

    this.contactService.saveContactUs(contactDetails).subscribe(response => {
      this.contactForm.reset();
      this.submitted = false;
      this.successMsg = 'Your query is sumbmitted successfully. We will contact you within 48 hours.'
    }, error => {
      console.log('Error:' + error.message);
      if (error.status === 409) {
        this.errMsg = 'Previous query is in progress.';
      } else {
        this.errMsg = 'Something went wrong, please try again';
      }
    });
  }

  validateCharacterForNumeric(event: any) {
    return this.customValidationService.validateCharacterForNumeric(event);
  }

  validateCharacterForAlphabets(event: any) {
    return this.customValidationService.validateCharacterForAlphabets(event);
  }

  validateCharacterPressedForEmail(event: any) {
    return this.customValidationService.validateCharacterPressedForEmail(event);
  }

  preventPaste(event: any) {
    return this.customValidationService.preventPaste(event);
  }

  handleSuccess(event: any) {
    this.isInvalidCaptcha = false;
  }

}
