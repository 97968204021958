<app-header></app-header>
<!-- book demo heading -->
<div class="service-banner">
    <div class="container">
        <div class="service-wrap">
            <h2>Book Demo</h2>
        </div>
    </div>
</div>
<!-- book demo form -->
<div class="contact-form-wrap">
    <div class="container">
        <div class="form-wrapper book-demo-wrap">
            <form [formGroup]="bookDemoForm" (ngSubmit)="onSubmit()">
                <div class="group-wrap">
                    <div class="input-wrap">
                        <label for="firstName" translate>book-demo.firstname</label>
                        <input type="text" formControlName="firstName"
                            (keypress)="validateCharacterForAlphabets($event)" (paste)="preventPaste($event)"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                        <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback pb-3">
                            <div *ngIf="f.firstName.errors.required" translate>book-demo.firstname-required-error</div>
                        </div>
                    </div>
                    <div class="input-wrap">
                        <label for="lname" translate>book-demo.lastname</label>
                        <input type="text" formControlName="lastName" (keypress)="validateCharacterForAlphabets($event)"
                            (paste)="preventPaste($event)" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                        <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback pb-3">
                            <div *ngIf="f.lastName.errors.required" translate>book-demo.lastname-required-error</div>
                        </div>
                    </div>
                </div>
                <div class="group-wrap">
                    <div class="input-wrap">
                        <label for="email" translate>book-demo.emailid</label>
                        <input type="text" formControlName="email" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" (paste)="preventPaste($event)"
                            (keypress)="validateCharacterPressedForEmail($event)" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback pb-3">
                            <div *ngIf="f.email.errors.required" translate>book-demo.email-required-error</div>
                            <div *ngIf="f.email.errors.email" translate>book-demo.email-validation-error</div>
                        </div>
                    </div>
                    <div class="input-wrap">
                        <label for="contact" translate>book-demo.contactno</label>
                        <input type="text" maxlength="15" formControlName="contact" class="form-control"
                            (paste)="preventPaste($event)" [ngClass]="{ 'is-invalid': submitted && f.contact.errors }"
                            (keypress)="validateCharacterForNumeric($event)" />
                        <div *ngIf="submitted && f.contact.errors" class="invalid-feedback pb-3">
                            <div *ngIf="f.contact.errors.required" translate>book-demo.contact-required-error</div>
                            <div *ngIf="f.contact.errors.minlength" translate>book-demo.contact-validation-error</div>
                        </div>
                    </div>
                </div>

                <div class="group-wrap">
                    <div class="input-wrap">
                        <label for="country" translate>book-demo.application</label>
                        <div class="checkbox￼
                        About us
                        Services
                        Product
                        Book Demo
                        -wrap">
                            <div class=" checkbox-col">
                                <label formArrayName="productCheckBoxGrp"
                                    *ngFor="let product of productsFormArray.controls; let i = index">
                                    <input type="checkbox" [formControlName]="i" value="{{products[i]}}">
                                    {{products[i]}}
                                </label>
                            </div>
                            <div class="invalid-feedback d-block pb-3" *ngIf="submitted && bookDemoForm.controls['productCheckBoxGrp'].errors && 
                                bookDemoForm.controls['productCheckBoxGrp'].errors.requireOneCheckboxToBeChecked">
                                At least one product is required to select</div>
                        </div>
                    </div>
                    <div class="input-wrap">
                        <label for="contact" translate>book-demo.bookdemoslot</label>
                        <input type="datetime-local" formControlName="bookingSlotDatetime" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.bookingSlotDatetime.errors }"
                            (paste)="preventPaste($event)" />

                        <div *ngIf="submitted" class="invalid-feedback d-block">
                            <div *ngIf="f.bookingSlotDatetime.errors && f.bookingSlotDatetime.errors.required">Bool Slot Datetime is required</div>
                            <div *ngIf="isInvalidBookSlot">{{bookSlotErrMsg}}</div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="g-recaptcha">
                        <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha"
                            (success)="handleSuccess($event)">
                        </ngx-recaptcha2>
                        <div class="invalid-feedback d-block pb-3">
                            <div *ngIf="isInvalidCaptcha">Captch is required</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="successMsg" class="alert alert-success" role="alert">
                    {{successMsg}}
                </div>
                <div *ngIf="errMsg" class="alert alert-danger" role="alert">
                    {{errMsg}}
                </div>
                <input type="submit" value="Submit">
            </form>
        </div>
    </div>
</div>
<app-footer></app-footer>