 <!-- footer section -->
 <footer>
    <div class="container">
        <div class="footer-inner">
            <h4 translate>footer.title
            </h4>
            <a href="" class="common-btn" translate><i class="fa-solid fa-arrow-right-long"></i>footer.subscribe</a>
            <div class="footer-menu-wrap">
                <a href="" class="footer-logo">
                    <img src="assets/images/logo-footer.png" alt="">
                </a>
                <ul class="footer-menu">
                    <li><a href="/about" translate>footer.about-us</a></li>
                    <li><a href="/contact" translate>footer.contact-us</a></li>
                    <li><a href="" translate>footer.career</a></li>
                    <li><a href="" translate>footer.locations</a></li>
                </ul>
                <ul class="social-footer-wrap">
                    <li>
                        <a href="">
                            <i class="fa-brands fa-linkedin"></i>
                        </a>
                    </li>
                    <li>
                        <a href="">
                            <i class="fa-brands fa-twitter"></i>
                        </a>
                    </li>
                    <li>
                        <a href="">
                            <i class="fa-brands fa-facebook-f"></i>
                        </a>
                    </li>
                    <li>
                        <a href="">
                            <i class="fa-brands fa-youtube"></i>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="copyright-wrap">
                <ul class="sub-footer-menu">
                    <li>
                        <a href="" translate>footer.privacy-stmt</a>
                    </li>
                    <li>
                        <a href="" translate>footer.term-conditions</a>
                    </li>
                    <li>
                        <a href="" translate>footer.cookie-policy</a>
                    </li>
                    <li>
                        <a href="" translate>footer.accessibility-stmt</a>
                    </li>
                    <li>
                        <a href="" translate>footer.sitemap</a>
                    </li>
                </ul>
                <p>&copy; 2022 Data Dimensions. All Rights Reserved.</p>
            </div>
        </div>
    </div>
</footer>


<!-- social icons -->
<ul class="social-icon-wrap">
    <li>
        <a href="">
            <i class="fa-brands fa-linkedin-in"></i>
        </a>
    </li>
    <li>
        <a href="">
            <i class="fa-brands fa-twitter"></i>
        </a>
    </li>
    <li>
        <a href="">
            <i class="fa-brands fa-facebook-f"></i>
        </a>
    </li>
    <li>
        <a href="">
            <i class="fa-brands fa-youtube"></i>
        </a>
    </li>
</ul>